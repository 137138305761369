/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash) {
    window.scroll({ top: 0 })
  } else {
    return true
  }
}
